import algoliasearch from 'algoliasearch';
import { prepareProspectForConversation } from '../../utils/prepareProspectForConversation';

export const updateAlgolia = async ({ objectId, data, refresh, prospect }) => {
  const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY);
  const index = client.initIndex(process.env.REACT_APP_ALGOLIA_INDEX);

  const update = prospect
    ? {
        prospect: {
          ...prepareProspectForConversation(prospect),
          ...data,
        },
      }
    : {
        ...data,
      };

  try {
    await index
      .partialUpdateObject(
        {
          objectID: objectId,
          ...update,
        },
        { createIfNotExists: false }
      )
      .wait();

    if (refresh) {
      refresh();
    }
  } catch (error) {
    throw new Error(error);
  }
};
