import PropTypes from 'prop-types';
import { CircularProgress, Stack } from '@mui/material';

const WaitComponent = ({ children }) => (
  <Stack direction="row" spacing={2}>
    {children}&nbsp;
    <CircularProgress size={20} />
  </Stack>
);

WaitComponent.propTypes = {
  children: PropTypes.node,
};

export default WaitComponent;
