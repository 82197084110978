import { getFirestore, collection, doc, getDoc, query, where, getDocs, getCountFromServer } from 'firebase/firestore';

const withIndex = false;

const activityFields = [
  {
    action: 'emailSent',
    title: 'Emails Sent (Activity)',
  },
  {
    action: 'messageOpened',
    title: 'Emails Opened',
  },
  {
    action: 'emailLinkClicked',
    title: 'Links Clicked',
  },
  {
    action: 'threadReplied',
    title: 'Replies',
  },
  {
    action: 'unsubscribe',
    title: 'Unsubscribed',
  },
  {
    action: 'bounce',
    title: 'Bounced',
  },
  {
    action: 'unsubscribed',
    title: 'Unsubscribed',
  },
  {
    action: 'journeyCompleted',
    title: 'Journey Completed',
  },
];

const twentyFourHoursAgo = new Date();
twentyFourHoursAgo.setDate(twentyFourHoursAgo.getDate() - 1);
twentyFourHoursAgo.setHours(0, 0, 0, 0);

const getImportedPeopleTodayByCampaign = async (db, campaignId) => {
  console.log(`Getting imported people for campaign ${campaignId}...`);
  const campaignRef = doc(db, 'campaigns', campaignId);

  const peopleInCampaignRef = collection(campaignRef, 'people');
  const q = query(peopleInCampaignRef, where('addedOn', '>=', twentyFourHoursAgo));
  const snapshot = await getCountFromServer(q);
  const importedPeople = snapshot.data().count;

  console.log(`Imported people for campaign ${campaignId}: ${importedPeople}`);
  return importedPeople;
};

const byCampaign = async (db, id) => {
  console.log(`Processing campaign ${id}...`);
  const campaignId = id;
  const campaignRef = doc(db, 'campaigns', campaignId);

  const campaignDoc = await getDoc(campaignRef);
  const campaign = campaignDoc.data();

  const importedPeople = await getImportedPeopleTodayByCampaign(db, campaignId);
  const today = new Date();

  const accounts = campaign.accounts.map((account) => account.email_address);

  const campaignData = {
    Date: today.toLocaleDateString(),
    'Campaign ID': campaignId,
    'Campaign Name': campaign.title,
    Accounts: accounts.join(', '),
    'People imported': importedPeople,
    'Total Emails Sent': campaign.sentTotal,
  };

  await Promise.all(
    activityFields.map(async (activityField) => {
      try {
        console.log(`Processing activity field ${activityField.title} for campaign ${campaignId}...`);
        const activitiesRef = collection(campaignRef, 'activities');
        const q = query(
          activitiesRef,
          where('action', '==', activityField.action),
          where('on', '>=', twentyFourHoursAgo)
        );

        if (activityField?.action === 'emailSent') {
          if (!withIndex) {
            const emailsSentSnapshot = await getDocs(q);
            const priority = emailsSentSnapshot.docs.filter((doc) => doc.data().stepIndex === 0).length;
            const nonPriority = emailsSentSnapshot.docs.filter((doc) => doc.data().stepIndex !== 0).length;

            campaignData['Conversations started'] = priority;
            campaignData['Follow-ups sent'] = nonPriority;
            campaignData['Emails Sent (Activity)'] = emailsSentSnapshot.docs.length;

            console.log(`Processed activity field ${activityField.title} for campaign ${campaignId}`);
            return;
          }
          const allQuery = query(q);
          const allCount = await getCountFromServer(allQuery);

          campaignData['Emails Sent (Activity)'] = allCount.data().count;

          const priorityQuery = query(q, where('stepIndex', '==', 0));
          const priorityCount = await getCountFromServer(priorityQuery);

          campaignData['Conversations started'] = priorityCount.data().count;

          campaignData['Follow-ups sent'] = allCount.data().count - priorityCount.data().count;

          console.log(`Processed activity field ${activityField.title} for campaign ${campaignId}`);
          return;
        }

        const countSnapshot = await getCountFromServer(q);
        console.log(`Campaign ${campaignId} has ${countSnapshot.data().count} ${activityField.title} activities`);
        campaignData[activityField.title] = countSnapshot.data().count;
      } catch (error) {
        debugger;
        console.error(error);
      }
    })
  );

  console.log(`Finished processing campaign ${campaignId}`);
  return campaignData;
};

export const createReportForUser = async (uid) => {
  console.log(`Creating report for user ${uid}...`);
  try {
    const db = getFirestore();
    const campaignsRef = collection(db, 'campaigns');
    const q = query(campaignsRef, where('createdBy', '==', uid), where('status', '==', 'running'));
    const querySnapshot = await getDocs(q);

    const campaigns = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    if (!campaigns.length) {
      console.log(`No campaigns found for user ${uid}`);
      return {
        statusCode: 200,
        body: `No campaigns found for user ${uid}`,
      };
    }

    const csvData = [];

    await Promise.all(
      campaigns.map(async (campaign) => {
        console.log(`Processing campaign ${campaign.id} for user ${uid}...`);
        const campaignData = await byCampaign(db, campaign.id);
        csvData.push(campaignData);
        console.log(`Finished processing campaign ${campaign.id} for user ${uid}`);
      })
    );

    console.log(`Finished creating report for user ${uid}`);
    return {
      statusCode: 200,
      body: `Report created for user ${uid}`,
      report: csvData,
    };
  } catch (error) {
    console.error(error);
    return {
      statusCode: 500,
      body: JSON.stringify(error),
    };
  }
};
