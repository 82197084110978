import { containsStripeRole, containsRole } from '../../utils/auth';

const navChecker = (item, user) => {
  const isNotUndef = item && item.stripeRole !== undefined;
  const containsRoleStripe = containsStripeRole({ role: user?.stripeRole, accessibleRoles: item?.stripeRole });
  const isTeamMember = user?.isTeamMember;
  const containsTeamRole = containsRole({ role: user?.role, accessibleRoles: item?.role });

  const hide = item?.hide ? user[item?.hide] : false;

  const containsRoleNormal =
    !isTeamMember || (isTeamMember && user?.isTeamLeader) || (isTeamMember && containsTeamRole);

  const isStripeOK = isNotUndef && containsRoleStripe;
  const isNormalOK = containsRoleNormal || !item?.role;

  const show = isStripeOK && isNormalOK && !hide;
  return show;
};

export default navChecker;
