import { httpsCallable } from 'firebase/functions';
import { collection, doc, getDoc, setDoc, Timestamp } from 'firebase/firestore';
import { FirebaseFunctions, FirestoreInstance } from '../../contexts/Firebase';

export const dataGetter = httpsCallable(FirebaseFunctions, 'dataGetter');

export const fetchActivitiesFromBackend = (data) => dataGetter({ func: 'fetchActivitiesFromBackend', ...data });
export const fetchActivitiesFromBackendAlt = (data) => dataGetter({ func: 'fetchActivitiesFromBackendAlt', ...data });
export const fetchGraphFromBackend = (data) => dataGetter({ func: 'fetchGraphFromBackend', ...data });


export const createActivity = async ({ action, data: { id, status, campaignId, prospect, conversationByThreadId }, uid }) => {
  try {
    const now = Timestamp.now();
    const campaignRef = doc(FirestoreInstance, 'campaigns', campaignId);
    const campaign = (await getDoc(campaignRef)).data();
    const activity = {
      action,
      data: {
        leadStatus: status,
        prospectId: id,
        prospect: prospect.info,
      },
      campaignId,
      clientId: campaign.client,
      uid,
      on: now,
      account: {
        emailAddress: conversationByThreadId.account.email || conversationByThreadId.account.emailAddress,
        accountId: conversationByThreadId.account_id || conversationByThreadId.account.account_id || conversationByThreadId.account.accountId,
      },
    };

    const activityRef = collection(FirestoreInstance, `campaigns/${campaignId}/activities`);
    const activityDoc = doc(activityRef);
    await setDoc(activityDoc, activity);
    return activityDoc.id;
  } catch (error) {
    return error.message;
  }
};