import { doc, getDoc } from 'firebase/firestore';
import { pick } from 'lodash';

import { UserFields, getAlias, ROLE_ALIAS } from '../../config';
import { FirestoreInstance } from '../Firebase';

const roleBasedGuarding = (roles) =>
  roles && roles.length > 0
    ? {
        isOwner: roles?.includes('OWNER') ?? false,
        isAdmin: roles?.includes('ADMIN') ?? false,
        isSuperAdmin: roles?.includes('SUPERADMIN') ?? false,
        isUser: roles?.includes('USER') ?? false,
        isClient: roles?.includes('CLIENT') ?? false,
        isTeamLeader: (roles?.includes('orgAdmin') || roles?.includes('teamLeader')) ?? false,
        isTeamManager: roles?.includes('teamManager') ?? false,
        isInboxManager: roles?.includes('inboxManager') ?? false,
        isCopywriter: roles?.includes('copywriter') ?? false,
        isReadOnly: roles?.includes('readOnly') ?? false,
        role: getAlias(roles),
      }
    : {};

const addStripeRoles = (stripeRole) =>
  stripeRole
    ? {
        isSolo: stripeRole === 'solo',
        isAgency: ['agency', 'enterprise'].includes(stripeRole),
        isEnterprise: stripeRole === 'enterprise',
        isUser: ['solo', 'agency', 'enterprise'].includes(stripeRole),
        isNullStripeRole: stripeRole === null || stripeRole === '',
        stripeRole,
      }
    : {};

const getOrg = async (user) => {
  const { orgId } = user.data();

  const orgData = {
    orgId: orgId || user?.id,
    isTeamMember: false,
    isTeamLeader: false,
    org: null,
    hasNoTeam: true,
  };

  const orgRef = doc(FirestoreInstance, 'orgs', orgId || user?.id);
  try {
    const orgSnap = await getDoc(orgRef);

    if (orgSnap.exists()) {
      orgData.org = orgSnap.data();
      orgData.isTeamMember = orgData?.org?.members?.includes(user.id);
      orgData.isTeamLeader = orgSnap.id === user.id;
      orgData.hasNoTeam = false;
      if (orgSnap.data().cube) {
        orgData.analytics = 'cube';
      }
    }

    return orgData;
  } catch (err) {
    return orgData;
  }
};

const getOrgRole = ({ roles, user }) => {
  if (!roles || roles.length === 0) {
    return { role: 'user' };
  }
  // filter out normal roles
  const rolesNotInRoleAlias = roles.filter((role) => !Object.keys(ROLE_ALIAS).includes(role));

  // if no roles but orgId === user.id then return orgAdmin
  if (rolesNotInRoleAlias.length === 0 && user?.orgId === user?.id && user?.isTeamMember) {
    return { role: 'teamLeader' };
  }

  // get the first role that is in the role alias
  const orgRole = rolesNotInRoleAlias[0];
  if (!orgRole) return {};
  return { role: orgRole };
};

const getToken = async (user) => {
  let [roles, stripeRole, clientSecret, apiKey] = [[], '', '', '', ''];

  // while (!(roles && roles.length > 0)) {
  // eslint-disable-next-line no-await-in-loop
  const token = await user.getIdTokenResult(true);
  if (token && token.claims) {
    /* eslint-disable */
    if (token.claims.stripeRole) {
      stripeRole = token.claims.stripeRole;
    }
    if (token.claims.roles) {
      roles = token.claims.roles;
    }
    if (token.claims.clientSecret) {
      clientSecret = token.claims.clientSecret;
    }
    if (token.claims.apiKey) {
      apiKey = token.claims.apiKey;
    }
    /* eslint-enable */
  }

  return { roles, stripeRole, clientSecret, apiKey, token };
};

const setUserProfile = async (user, dispatch, setProfile) => {
  try {
    const { roles, stripeRole, clientSecret, apiKey, token } = await getToken(user);
    const cleanUser = pick(user, UserFields);

    const userRef = doc(FirestoreInstance, 'users', user?.uid);
    const docSnap = await getDoc(userRef);

    if (docSnap.exists()) {
      const userData = docSnap.data();
      const orgData = await getOrg(docSnap);
      const orgRole = getOrgRole({ roles, user });

      const profile = {
        getIdTokenResult: () => user.getIdTokenResult(true),
        token,
        ...userData,
        isVerified: user.emailVerified,
        uid: user?.uid,
        id: user?.uid,
        roles,
        clientSecret,
        apiKey,
        ...roleBasedGuarding(roles),
        ...addStripeRoles(stripeRole),
        ...orgData,
        ...orgRole,
        displayName: userData?.displayName
          ? userData?.displayName
          : user?.displayName
          ? user?.displayName
          : user?.firstName && user?.lastName
          ? `${user?.firstName} ${user?.lastName}`
          : '',
        securityReview: userData?.securityReview || false,
      };

      // if process env is not production
      if (process.env.NODE_ENV !== 'production') {
        console.log('User profile', profile);
      }

      setProfile(profile);

      dispatch({
        type: 'INITIALISE',
        payload: { isAuthenticated: true, user: cleanUser },
      });
    } else {
      dispatch({
        type: 'INITIALISE',
        payload: { isAuthenticated: false, user: null },
      });
    }
  } catch (error) {
    console.log('Error in setUserProfile', error);
    dispatch({
      type: 'INITIALISE',
      payload: { isAuthenticated: false, user: null },
    });
  }
};

export default setUserProfile;
