import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { useTheme } from '@mui/material/styles';

export default function ProgressBar({ loading }) {
  const ref = useRef(null);
  const theme = useTheme();

  const color = theme.palette.primary.light;

  useEffect(() => {
    if (!ref.current) return;

    if (loading) ref.current.continuousStart();
    if (!loading) ref.current.complete();
  }, [loading, ref]);

  return <LoadingBar height={3} color={color} ref={ref} />;
}

ProgressBar.propTypes = {
  loading: PropTypes.bool,
};
