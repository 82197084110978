import { doc, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { FirestoreInstance } from '../../contexts/Firebase';
import { customTeamRoleSetter } from './usersClients';

export const createTeam = async (user) => {
  if (user?.isTeamMember || user?.isSolo) {
    throw new Error('You cannot create your own a team');
  }

  const org = {
    createdAt: Timestamp.now(),
    id: user?.uid,
    members: [user?.uid],
    admin: user?.uid,
  };

  await setDoc(doc(FirestoreInstance, 'orgs', user?.uid), org);

  const userRef = doc(FirestoreInstance, 'users', user?.uid);
  await updateDoc(userRef, { orgId: user?.uid, isTeamLeader: true, role: 'teamLeader' });

  await await customTeamRoleSetter({
    uid: user?.uid,
    role: 'teamLeader',
  });

  return org;
};
