import { FirestoreProvider, AuthProvider, useInitPerformance } from 'reactfire';
import { connectFirestoreEmulator } from 'firebase/firestore';
import { connectAuthEmulator } from 'firebase/auth';
import { connectFunctionsEmulator } from 'firebase/functions';
import { IntercomProvider } from 'react-use-intercom';

import { connectStorageEmulator } from 'firebase/storage';
import { ModalProvider } from 'react-modal-hook';
import { DialogProvider } from 'muibox';
import { ConfirmProvider } from 'material-ui-confirm';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import ThemeSettings from './components/visualSettings/index';

import { GlobalState } from './contexts/GlobalState';
import { AuthProvider as FirebaseAuthProvider } from './contexts/AuthContext';
import { ActionsProvider } from './contexts/ActionsContext';

// components
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import useScheduledBackendTasks from './hooks/useScheduledBackendTasks';
import { FirestoreInstance, FirebaseAuth, FirebaseFunctions, StorageInstance } from './contexts/Firebase';
// ----------------------------------------------------------------------

export default function App() {
  const EMULATORS_STARTED = 'EMULATORS_STARTED';

  function startEmulators() {
    if (!global[EMULATORS_STARTED]) {
      global[EMULATORS_STARTED] = true;
      // Set up emulators
      connectFirestoreEmulator(FirestoreInstance, 'localhost', 8080);
      connectAuthEmulator(FirebaseAuth, 'http://localhost:9099', { disableWarnings: true });
      connectFunctionsEmulator(FirebaseFunctions, 'localhost', 5001);
      connectStorageEmulator(StorageInstance, 'localhost', 9199);
    }
  }

  if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_LOCAL_DEV === 'true') {
    startEmulators();
  }

  useInitPerformance(async (firebaseApp) => {
    const { getPerformance } = await import('firebase/performance');
    return getPerformance(firebaseApp);
  });

  const ConfirmProviderOptions = {
    confirmationButtonProps: { autoFocus: true },
    dialogProps: { maxWidth: 'xs' },
    cancellationButtonProps: { color: 'error' },
  };

  useScheduledBackendTasks();

  return (
    <GlobalState>
      <AuthProvider sdk={FirebaseAuth}>
        <FirestoreProvider sdk={FirestoreInstance}>
          <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID}>
            <ThemeProvider>
              <ThemeSettings>
                <ThemeColorPresets>
                  <RtlLayout>
                    <NotistackProvider>
                      <FirebaseAuthProvider>
                        <DialogProvider>
                          <ConfirmProvider defaultOptions={ConfirmProviderOptions}>
                            <ActionsProvider>
                              <ModalProvider>
                                <MotionLazyContainer>
                                  <ChartStyle />
                                  <ScrollToTop />
                                  <Router />
                                </MotionLazyContainer>
                              </ModalProvider>
                            </ActionsProvider>
                          </ConfirmProvider>
                        </DialogProvider>
                      </FirebaseAuthProvider>
                    </NotistackProvider>
                  </RtlLayout>
                </ThemeColorPresets>
              </ThemeSettings>
            </ThemeProvider>
          </IntercomProvider>
        </FirestoreProvider>
      </AuthProvider>
    </GlobalState>
  );
}
