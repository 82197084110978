import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions } from 'firebase/functions';

import { FIREBASE_API } from '../config';

// ----------------------------------------------------------------------

// console.log('YOLO PROCESS', process.env.REACT_APP_URL);

export const FirebaseApp = initializeApp(FIREBASE_API);
export const appCheck = initializeAppCheck(FirebaseApp, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_CAPTCHA_SITE_KEY),
  isTokenAutoRefreshEnabled: true,
});

export const FirestoreInstance = getFirestore(FirebaseApp);
export const FirebaseAuth = getAuth(FirebaseApp);
export const FirebaseFunctions = getFunctions(FirebaseApp);
export const StorageInstance = getStorage(FirebaseApp);
export const analytics = getAnalytics(FirebaseApp);

