import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useState, useTransition } from 'react';

import { camelize, pascalize } from 'fast-case';
import { useConfirm } from 'material-ui-confirm';
import { useDialog } from 'muibox';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import csvDownload from 'json-to-csv-export';

import {
  accountReset as accountResetFunction,
  addAutomationToUserCallable,
  addCampaignToUser,
  addEmailAccountToClient as addEmailAccountToClientFinal,
  addImmediateBatch as addImmediateBatchFinal,
  addInvitation as addInvitationFinal,
  addNewScheduleToUser,
  addNewSequence,
  addNextBatchOfProspects as addNextBatchOfProspectsFinal,
  addPeopleToCampaign,
  addRemoveFromBlacklist,
  campaignReset as campaignResetFunction,
  campaignRunner as campaignRunnerFinal,
  clientrunner as clientrunnerFunction,
  commitCampaignPriority as commitCampaignPriorityFinal,
  createActivity as createActivityFinal,
  createClient as createClientFinal,
  createTeam as createTeamFinal,
  createTeamMember as createTeamMemberFinal,
  setCustomClaims as customClaimsSetter,
  deleteAutomation as deleteAutomationFinal,
  deleteCampaign as deleteCampaignFinal,
  deleteClient as deleteClientFinal,
  deleteInvitation as deleteInvitationFinal,
  deleteNylasAccount as deleteNylasAccountFinal,
  deleteProspects as deleteProspectsFinal,
  deleteSchedule as deleteScheduleFinal,
  deleteSequence as deleteSequenceFinal,
  duplicateCampaign as duplicateCampaignFinal,
  editAccountData as editAccountDataFinal,
  editAccount as editAccountFinal,
  editAutomationCallable,
  editAutomationField as editAutomationFieldFinal,
  editCampaign as editCampaignFinal,
  editCampaignOptions as editCampaignOptionsFinal,
  editCampaignSchedule as editCampaignScheduleFinal,
  editCampaignSequence as editCampaignSequenceFinal,
  editClient as editClientFinal,
  editInvitationField as editInvitationFieldFinal,
  editInvitation as editInvitationFinal,
  editProspect as editProspectFinal,
  launchCampaign as launchCampaignFinal,
  pauseCampaign as pauseCampaignFinal,
  reScore as reScoreFinal,
  restartCampaign as restartCampaignFinal,
  saveOnboarding as saveOnboardingFinal,
  setAsClientAccountsDefault as setAsClientAccountsDefaultFinal,
  setGoogleSheetMap as setGoogleSheetMapFinal,
  unsubscribe as unsubscribeFinal,
  updateProspect as updateProspectFinal,
  updateUser as updateUserFinal,
  validateGoogleSheet as validateGoogleSheetFinal,
  verifyEmail,
  whitelistDomain as whitelistDomainFinal,
  getUserReportBackend as getUserReportBackendFinal,
  getUserReportFrontend as getUserReportFrontendFinal,
} from '../backend/firestoreFunctions';
import useAuth from '../hooks/useAuth';
import { useGlobalState } from './GlobalState';

import { toggleClientrunner as toggleClientrunnerFinal } from '../backend/clientrunner';
import { manualBackup as manualBackupFinal } from '../backend/manualBackup';
import { warmup } from '../backend/toggleWarmup';

import {
  archive as archiveFinal,
  markAsRead as markAsReadFinal,
  toggleArchive as toggleArchiveFinal,
  unarchive as unarchiveFinal,
} from '../backend/mailboxFunctions';

import createCheckoutSessionFinal from '../backend/stripe/createCheckoutSession';

import { base64ImageUpload } from '../backend/storageFunctions';
import ProgressBar from '../components/ProgressBar';
import { PATH_DASHBOARD } from '../routes/paths';

import { updateAlgolia } from '../backend/firestore/algolia';
import { testWebhookCallable } from '../backend/firestore/automations';
import { importPeopleFromGoogleSheet as importPeopleFromGoogleSheetFinal } from '../backend/firestore/campaigns';
import { createUserApiKey } from '../backend/firestore/integrations';
import { timeout } from '../utils/stuff';
import WaitComponent from './partials/WaitComponent';
// import { dropNullUndefined, removeEmpty } from '../utils/transformers';

const ActionsContext = createContext({});

const ActionsProvider = ({ children }) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [superLoading, setSuperLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const dialog = useDialog();
  const [globalState, globalDispatch] = useGlobalState();
  const [isPending, startCalc] = useTransition();

  const snack = (message, variant) => enqueueSnackbar(message, { variant: variant || 'success' });

  const setMailAccountClaims = ({ uid, account }) =>
    customClaimsSetter({ uid, claims: { accounts: { ...user.accounts, account } } });

  const uploadBase64Image = async ({ screenshot, title }) => {
    let imageURL = '';
    try {
      if (screenshot && title) {
        imageURL = await base64ImageUpload(screenshot, title);
        await timeout(1500);
        return imageURL;
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  // Actions:
  const actionCreator = useCallback(
    async ({
      preFunction,
      f,
      snackbar,
      data,
      confirmDescription,
      transition,
      navigatePath,
      reload = false,
      after,
      load = true,
    }) => {
      if (preFunction) {
        await preFunction();
      }
      if (load) {
        setLoading(true);
      }
      let x = null;
      if (confirmDescription) {
        try {
          await confirm({ description: confirmDescription });
        } catch (err) {
          enqueueSnackbar(`Maybe next time`, { variant: 'error' });
          setLoading(false);
          return;
        }
      }
      try {
        if (transition) {
          setSuperLoading(true);
          await startCalc(async () => {
            x = await f(data);
          });
        } else {
          x = await f(data);
        }

        if (snackbar && load) {
          enqueueSnackbar(snackbar);
        }

        if (after) {
          return after(x);
        }

        if (navigatePath) {
          navigate(navigatePath);
          if (reload) {
            window.location.reload();
          }
        }
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      } finally {
        setLoading(false);
        if (transition) {
          setSuperLoading(false);
        }
      }
    },
    [confirm, enqueueSnackbar, navigate]
  );

  const createClientSuccess = () =>
    actionCreator({
      f: () => globalDispatch({ ...globalState, confetti: true }),
      snackbar: 'You created a client! Awesome!',
      navigatePath: PATH_DASHBOARD.clients.list,
      reload: true,
    });

  const addCustomField = ({ uid, field }) =>
    actionCreator({
      f: ({ uid, field }) => {
        const customFields = {
          [pascalize(field)]: {
            fullKey: pascalize(field),
            key: camelize(field),
          },
        };

        return editClientFinal({ uid, customFields });
      },
      snackbar: `Successfully added custom field '${field}'`,
      data: { uid, field },
    });

  const editSchedule = (schedule) =>
    actionCreator({
      f: addNewScheduleToUser,
      data: { uid: user?.orgId, schedule, id: schedule.id },
      snackbar: 'Successfully edited schedule',
      navigatePath: PATH_DASHBOARD.templates.schedules.root,
    });

  const deleteSchedule = (id) =>
    actionCreator({
      f: deleteScheduleFinal,
      data: { uid: user?.orgId, id },
      snackbar: 'Successfully deleted schedule',
      navigatePath: PATH_DASHBOARD.templates.schedules.root,
      confirmDescription: 'Are you sure you want to delete this schedule?',
    });

  const deleteSequence = (id) =>
    actionCreator({
      f: deleteSequenceFinal,
      data: { uid: user?.orgId, id },
      snackbar: 'Successfully deleted sequence',
      navigatePath: PATH_DASHBOARD.templates.sequences.root,
      confirmDescription: 'Are you sure you want to delete this sequence?',
    });

  const createClient = (data) =>
    actionCreator({
      f: createClientFinal,
      snackbar: `Client created succesfully, with email ${data.email}`,
      data: { user, ...data },
      navigatePath: PATH_DASHBOARD.clients.list,
      reload: true,
    });

  const createTeam = () =>
    actionCreator({
      f: createTeamFinal,
      data: user,
      snackbar: `Successfully created team`,
      navigatePath: PATH_DASHBOARD.team.root,
      reload: true,
    });

  const createTeamMember = (data) =>
    actionCreator({
      f: createTeamMemberFinal,
      snackbar: `Client team member succesfully, with email ${data.email}`,
      data: { user, ...data },
      navigatePath: `${PATH_DASHBOARD.team.root}`,
    });

  const deleteClient = (uid) =>
    actionCreator({
      f: deleteClientFinal,
      snackbar: `Successfully deleted client with id ${uid}`,
      data: uid,
      confirmDescription: 'Deleting a user cannot be undone',
    });

  const updateClientOrUserInDB = ({ uid, ...data }) =>
    actionCreator({
      f: editClientFinal,
      snackbar: `Successfully updated`,
      data: { uid, ...data },
    });

  const commitCampaignPriority = ({ uid, campaigns }) =>
    actionCreator({
      f: commitCampaignPriorityFinal,
      data: { uid, campaigns },
      snackbar: `Successfully committed campaign priority`,
    });

  const updateUser = (data, snackbar) =>
    actionCreator({
      f: updateUserFinal,
      snackbar: snackbar || `Successfully updated ${data?.displayName}`,
      data,
    });

  const clientrunner = (id) =>
    actionCreator({
      f: clientrunnerFunction,
      snackbar:
        id === 'all'
          ? `Successfully sent a shit-ton of emails`
          : `Successfully went through the next jobs for client ${id}`,
      data: id,
      confirmDescription:
        id !== 'all'
          ? "This will send the next job in the queue. Proceed only if you're very sure"
          : "This will create a queue with all possible jobs and send them. Proceed only if you're very sure",
    });

  const accountReset = (campaignId) =>
    actionCreator({
      f: accountResetFunction,
      snackbar: `Successfully reset accounts`,
      data: campaignId,
      confirmDescription: "This will reset all accounts. Proceed only if you're very sure",
    });

  const campaignReset = (campaignId) =>
    actionCreator({
      f: () => campaignResetFunction(),
      snackbar: `Successfully reset campaigns`,
      data: campaignId,
      confirmDescription: "This will reset all campaigns. Proceed only if you're very sure",
    });

  const launchCampaign = ({ id, campaign, conditions }) =>
    actionCreator({
      f: launchCampaignFinal,
      snackbar: `Congratulations. Your campaign is live!`,
      confirmDescription: `Prospects will be added. Emails will be sent. That could mean now.`,
      data: { id, uid: user?.orgId, campaign, conditions },
    });

  const pauseCampaign = (id) =>
    actionCreator({
      f: pauseCampaignFinal,
      data: id,
      snackbar: `Campaign paused`,
      confirmDescription: `This will pause the campaign. Are you sure you want to do that?`,
    });

  const restartCampaign = (id) =>
    actionCreator({
      f: restartCampaignFinal,
      snackbar: `Successfully restarted campaign`,
      confirmDescription: `This will restart the campaign and override current calculations. Are you sure you want to do that?`,
      data: id,
      navigatePath: `${PATH_DASHBOARD.campaigns.root}/${id}`,
    });

  const addNextBatchOfProspects = (data) =>
    actionCreator({
      f: addNextBatchOfProspectsFinal,
      snackbar: `Successfully added users`,
      data,
      confirmDescription: 'This will add the next batch of users to the campaign. Are you sure you want to do that?',
    });

  const addImmediateBatch = ({ campaignId, batchSize }) =>
    actionCreator({
      f: addImmediateBatchFinal,
      snackbar: `Successfully added batch`,
      data: { campaignId, batchSize },
    });

  const deleteNylasAccount = ({ clientId, account }) =>
    actionCreator({
      f: deleteNylasAccountFinal,
      confirmDescription: `Are you sure about this? You will have to reconnect the account later on`,
      data: { uid: user?.orgId, clientId, account },
      snackbar: `Successfully deleted account with email ${account.email_address}`,
    });

  const addEmailAccountToClient = (data) =>
    actionCreator({
      f: addEmailAccountToClientFinal,
      data,
      snackbar: `Connected ${data?.account?.email_address} successfully!`,
      after: () => globalDispatch({ ...globalState, confetti: true }),
    });

  const editAccount = (data) =>
    actionCreator({
      f: editAccountFinal,
      data,
      snackbar: `Edited ${data?.account?.email_address} successfully!`,
    });

  const editAccountData = (data) =>
    actionCreator({
      f: editAccountDataFinal,
      data,
      snackbar: `Edited ${data?.account?.email_address} successfully!`,
    });

  const setAsClientAccountsDefault = (account, sending) =>
    actionCreator({
      f: setAsClientAccountsDefaultFinal,
      data: account,
      confirmDescription: `This will set this accounts's ${
        sending ? 'sending' : 'warmup'
      } settings as default  for every account under this user`,
      snackbar: `Successfully set ${account.email_address}'s settings as default`,
    });

  const addBucket = ({ bucket, campaignId, clientId, file }) =>
    actionCreator({
      f: addPeopleToCampaign,
      data: { bucket, campaignId, clientId, file, user },
      snackbar: `Successfully added people to campaign`,
      navigatePath: `${PATH_DASHBOARD.campaigns.root}/${campaignId}`,
      reload: true,
    });

  const setGoogleSheetMap = ({ columnMap, googleSpreadsheetId, googleSheetId, campaignId }) =>
    actionCreator({
      f: setGoogleSheetMapFinal,
      data: { columnMap, googleSpreadsheetId, googleSheetId, campaignId },
      after: () => importPeopleFromGoogleSheet(campaignId),
    });

  const importPeopleFromGoogleSheet = (campaignId) =>
    actionCreator({
      f: importPeopleFromGoogleSheetFinal,
      data: campaignId,
      after: ({ data: { statusCode, message } }) => {
        if (Number(statusCode) === 200) {
          snack('Successfully mapped and imported people from Google Sheet', 'success');
        } else {
          if (message) {
            snack(message, 'error');
            return;
          }
          snack('Something went wrong. Please contact your admin', 'error');
        }
      },
    });

  const saveSequence = ({ uid, sequence, id, title, redirect }) =>
    actionCreator({
      f: addNewSequence,
      data: { uid, sequence, id, title },
      snackbar: !id ? `Successfully added sequence` : `Successfully edited sequence`,
      ...(redirect && { navigatePath: PATH_DASHBOARD.templates.sequences.root }),
    });

  const addSchedule = async (schedule, asTemplate = false) => {
    let scheduleTitle = schedule.title;
    if (scheduleTitle === '' || !scheduleTitle) {
      try {
        scheduleTitle = await dialog.prompt('Please add a title for this schedule');
      } catch (error) {
        throw new Error('Maybe next time');
      }
    }

    return actionCreator({
      f: addNewScheduleToUser,
      data: { uid: user.uid, schedule: { ...schedule, title: scheduleTitle }, asTemplate },
      snackbar: `Successfully added schedule`,
      navigatePath: asTemplate || PATH_DASHBOARD.templates.schedules.root,
    });
  };

  const addToBlacklist = ({ emails, uid, field = 'blacklist' }) =>
    actionCreator({
      f: addRemoveFromBlacklist,
      data: { uid, emails, add: true, field },
      snackbar: `Successfully added ${emails.join(', ')} to blacklist`,
    });

  const removeFromBlacklist = ({ emails, uid, field = 'blacklist' }) =>
    actionCreator({
      f: addRemoveFromBlacklist,
      data: { uid, emails, add: false, field },
      snackbar: `Successfully removed ${emails.join(', ')} from blacklist`,
    });

  const editCampaignSequence = ({ sequence, id, status }) =>
    actionCreator({
      f: editCampaignSequenceFinal,
      data: { uid: user.uid, sequence, id },
      snackbar: `Successfully edited sequence`,
      confirmDescription:
        ['running', 'paused'].includes(status) &&
        `Changing the sequence will affect all future emails. Are you sure you want to do that?`,
    });

  const editCampaignSchedule = ({ schedule, id, status }) =>
    actionCreator({
      f: editCampaignScheduleFinal,
      data: { uid: user.uid, schedule, id },
      snackbar: `Successfully edited schedule`,
      confirmDescription:
        ['running', 'paused'].includes(status) &&
        `Changing the schedule will affect all future emails. Are you sure you want to do that?`,
    });

  const editCampaignOptions = ({ id, campaign }) =>
    actionCreator({
      f: editCampaignOptionsFinal,
      data: { id, options: campaign?.options, accounts: campaign?.accounts },
      snackbar: `Successfully edited campaign options`,
      confirmDescription:
        campaign?.status &&
        ['running', 'paused'].includes(campaign?.status) &&
        `Changing the campaign options will affect all future emails. Are you sure you want to do that?`,
    });

  const setCustomClaims = (data, snackbar = `Successfully set custom claims`) =>
    actionCreator({
      f: customClaimsSetter,
      data: { uid: user?.uid, claims: { ...data } },
      snackbar,
    });

  const whitelistDomain = (domain) =>
    actionCreator({
      f: whitelistDomainFinal,
      data: { uid: user?.orgId, domain },
      snackbar: 'Successfully whitelisted domain',
    });

  const campaignRunner = ({ id, email, prospectId }) =>
    actionCreator({
      f: campaignRunnerFinal,
      data: { id, email, prospectId },
      snackbar: 'Sent test campaign',
    });

  const addAutomation = (automation) =>
    actionCreator({
      f: addAutomationToUserCallable,
      data: { automation, uid: user?.orgId, ...(user?.isSolo && { clients: [user?.uid] }) },
      snackbar: `Successfully created automation`,
      navigatePath: PATH_DASHBOARD.automations.list,
    });

  const editAutomation = (automation) =>
    actionCreator({
      f: editAutomationCallable,
      data: { automation, uid: user?.orgId },
      snackbar: `Successfully edited automation`,
      navigatePath: PATH_DASHBOARD.automations.list,
    });

  const editAutomationField = ({ id, field }) =>
    actionCreator({
      f: editAutomationFieldFinal,
      data: { id, field, uid: user?.orgId },
      snackbar: `Successfully edited automation`,
      navigatePath: PATH_DASHBOARD.automations.list,
    });

  const deleteAutomation = (id) =>
    actionCreator({
      f: deleteAutomationFinal,
      data: { uid: user?.orgId, id },
      snackbar: `Successfully deleted automation`,
    });

  const addCampaign = ({ campaign }) =>
    actionCreator({
      f: addCampaignToUser,
      data: {
        campaign,
        user,
      },
      snackbar: `Successfully created campaign`,
      after: ({ id }) => {
        sessionStorage.removeItem('sequence');
        navigate(`${PATH_DASHBOARD.campaigns.root}/${id}/people`);
      },
    });

  const editCampaign = ({ campaign, id }) =>
    actionCreator({
      f: editCampaignFinal,
      data: { uid: user.uid, campaign, id },
      snackbar: `Successfully edited campaign`,
    });

  const editProspect = ({ campaignId, prospectId, prospectData, confirm }) =>
    actionCreator({
      f: editProspectFinal,
      data: { campaignId, prospectId, prospectData },
      snackbar: `Successfully edited prospect`,
      ...(confirm && { confirmDescription: 'Are you sure you want to edit this prospect?' }),
    });

  const deleteCampaign = (id) =>
    actionCreator({
      f: deleteCampaignFinal,
      confirmDescription: `Are you sure you want to delete this campaign?`,
      snackbar: `Successfully deleted campaign`,
      data: id,
    });

  const duplicateCampaign = (id) =>
    actionCreator({
      f: duplicateCampaignFinal,
      confirmDescription: `Are you sure you want to duplicate this campaign?`,
      snackbar: `Successfully duplicated campaign`,
      data: { id, uid: user?.orgId || user?.uid },
    });

  const deleteProspects = ({ prospects, campaignId }) =>
    actionCreator({
      f: deleteProspectsFinal,
      confirmDescription: `Are you sure you want to delete these prospects?`,
      data: { prospects, campaignId },
      snackbar: `Successfully deleted prospects`,
    });

  const testWebhook = ({ id, trigger }) =>
    actionCreator({
      f: testWebhookCallable,
      data: { id, trigger, uid: user?.orgId },
      after: ({ data: status }) => {
        if (status === 200) {
          snack('Successfully sent test webhook', 'success');
        } else {
          snack('Webhook failed', 'error');
        }
      },
    });

  const addInvitation = ({ roles, email }) =>
    actionCreator({
      f: addInvitationFinal,
      data: { roles, email, uid: user?.orgId },
      snackbar: `Successfully invited user`,
      navigatePath: PATH_DASHBOARD.invitations.list,
    });

  const editInvitation = (invitation) =>
    actionCreator({
      f: editInvitationFinal,
      data: { invitation, uid: user?.orgId },
      snackbar: `Successfully edited invitation`,
      navigatePath: PATH_DASHBOARD.invitations.list,
    });

  const editInvitationField = ({ email, field }) =>
    actionCreator({
      f: editInvitationFieldFinal,
      data: { email, field },
      snackbar: `Successfully edited invitation`,
    });

  const deleteInvitation = (data) =>
    actionCreator({
      f: deleteInvitationFinal,
      confirmDescription: 'Are you sure you want to delete this invitation?',
      data,
      snackbar: `Successfully deleted invitation`,
    });

  const unsubscribe = (data) =>
    actionCreator({
      f: unsubscribeFinal,
      confirmDescription: 'Are you sure you want to unsubscribe this prospect?',
      data: { ...data, uid: user?.orgId },
      snackbar: `Successfully unsubscribed user`,
    });

  const reScore = (data) =>
    actionCreator({
      f: reScoreFinal,
      confirmDescription: 'Are you sure you want to re-score this reply?',
      data: { ...data, uid: user?.orgId },
      load: false,
    });

  const archiveConversation = ({ threadId, prospectId, campaignId }) =>
    actionCreator({
      f: archiveFinal,
      data: { threadId, uid: user?.uid, prospectId, campaignId },
      snackbar: `Successfully archived conversation`,
    });

  const unarchiveConversation = ({ threadId, prospectId, campaignId }) =>
    actionCreator({
      f: unarchiveFinal,
      data: { threadId, uid: user?.uid, prospectId, campaignId },
      snackbar: `Successfully archived conversation`,
    });

  const toggleArchiveConversation = ({ threadId, prospectId, campaignId, archived, refresh }) =>
    actionCreator({
      preFunction: () =>
        snack(<WaitComponent>{archived ? 'Archiving' : 'Unarchiving'} conversation...</WaitComponent>, 'info'),
      f: updateAlgolia,
      data: {
        objectId: threadId,
        data: { archived },
        refresh,
      },
      snackbar: `Successfully ${!archived ? 'un' : ''}archived conversation`,
      after: () => toggleArchiveFinal({ threadId, uid: user?.uid, prospectId, campaignId, archived }),
    });

  const deleteConversation = ({ campaignId, prospectId }) =>
    actionCreator({
      f: deleteProspects,
      confirmDescription: 'This will archive this conversation.',
      data: { campaignId, prospects: [prospectId], uid: user?.orgId },
      snackbar: `Successfully archived conversation`,
    });

  const toggleClientrunner = (toggle) =>
    actionCreator({
      f: () => toggleClientrunnerFinal(toggle),
      confirmDescription: !toggle
        ? 'Are you sure you want to pause all campaigns?'
        : 'Are you sure you want to resume all campaigns?',
      snackbar: `Successfully ${!toggle ? 'paused' : 'resumed'} all campaigns`,
    });

  const toggleWarmupRun = () =>
    actionCreator({
      f: warmup,
      confirmDescription: 'Are you sure you want to run warmup?',
      snackbar: `Successfully run Warmup round`,
    });

  const createCheckoutSession = (price) =>
    actionCreator({
      stopLoad: false,
      f: createCheckoutSessionFinal,
      data: { price, uid: user?.orgId, email: user?.email },
      after: (url) => {
        window.location.href = url;
      },
    });

  const sendEmailVerificationFunction = () =>
    actionCreator({
      f: async () => {
        try {
          const currentUrl = window.location.href;

          await verifyEmail({ email: user?.email, redirectUrl: currentUrl });

          snack('Email verification sent', 'success');
        } catch (error) {
          snack(error.message, 'error');
        }
      },
      snackbar: 'Email verification sent',
    });

  const saveOnboarding = (data) =>
    actionCreator({
      f: saveOnboardingFinal,
      data: { ...data, new: false, uid: user?.orgId },
      snackbar: `Thank you! Let's select a plan`,
      navigatePath: data?.redirect ? PATH_DASHBOARD.user.subscription : false,
    });

  const toggleCampaignOrder = ({ uid, email, campaignOrder }) =>
    actionCreator({
      f: ({ uid, campaignOrder }) => editClientFinal({ uid, campaignOrder }),
      snackbar: campaignOrder ? `Campaigns will be ordered for ${email}` : `Campaigns order disabled for ${email}`,
      data: { uid, campaignOrder },
    });

  const manualBackup = () =>
    actionCreator({
      f: manualBackupFinal,
      snackbar: `Start backup?`,
    });

  const changeLeadStatus = ({ id, status, campaignId, conversationByThreadId, prospect, refresh }) =>
    actionCreator({
      preFunction: () => snack(<WaitComponent>Uploading to servers...</WaitComponent>, 'info'),
      f: updateAlgolia,
      data: {
        objectId: conversationByThreadId.threadId,
        data: {
          leadStatus: status,
        },
        prospect,
        refresh,
      },
      snackbar: `Successfully edited lead status`,
      after: async () => {
        await updateProspectFinal({ id, status, campaignId });
        await createActivityFinal({
          action: 'leadStatus',
          data: { id, status, campaignId, prospect, conversationByThreadId },
          uid: user?.orgId,
        });
      },
    });

  const markAsRead = ({ threadId, uid, refresh }) =>
    actionCreator({
      f: updateAlgolia,
      data: {
        objectId: threadId,
        data: { unread: false },
        refresh,
      },
      load: false,
      after: () => markAsReadFinal({ threadId, uid }),
    });

  const validateGoogleSheet = (spreadsheetId, sheetId) =>
    actionCreator({
      f: validateGoogleSheetFinal,
      data: { spreadsheetId, sheetId },
      after: async ({ data }) => {
        if (data.statusCode === 500) {
          return snack('Error validating Google Sheet', 'error');
        }
        if (data.statusCode === 200) {
          await snack('Successfully validated Google Sheet', 'success');
          return data.headerValues;
        }
      },
    });

  const addAPIKeyToUser = ({ integration, key }) =>
    actionCreator({
      f: createUserApiKey,
      data: { uid: user?.uid, integration, key },
      after: ({ data: { statusCode, message } }) => {
        if (statusCode === 200) {
          return snack(message, 'success');
        }

        if (statusCode === 500) {
          return snack(message, 'error');
        }
      },
    });

  const getUserReportBackend = () =>
    actionCreator({
      f: getUserReportBackendFinal,
      transition: true,
      data: user?.orgId,
      after: (report) => {
        debugger;
        const uid = user?.orgId;

        if (report) {
          const rightNow = new Date();
          const nowString = rightNow.toLocaleDateString().replace(/\//g, '-');
          const fileName = `report-${uid}-${nowString}.csv`;
          return csvDownload(report, fileName);
        }

        return snack('Successfully generated report', 'success');
      },
    });

  const getUserReportFrontend = () =>
    actionCreator({
      f: getUserReportFrontendFinal,
      data: user?.orgId,
      after: (data) => {
        const { report } = data;
        if (report) {
          const rightNow = new Date();
          const nowString = rightNow.toLocaleDateString().replace(/\//g, '-');
          const filename = `report-${user?.email}-${nowString}.csv`;
          return csvDownload({
            data: report,
            filename,
          });
        }
        if (data.statusCode === 200) {
          return snack(data.body, 'success');
        }
        return snack(data.body, 'error');
      },
    });

  const Actions = {
    loading,
    setLoading,
    superLoading,
    setSuperLoading,
    isPending,
    snack,
    updateUser,
    createClient,
    createTeam,
    createTeamMember,
    updateClientOrUserInDB,
    deleteClient,
    createClientSuccess,
    addCustomField,
    addBucket,
    addSchedule,
    editSchedule,
    deleteSchedule,
    setMailAccountClaims,
    saveSequence,
    deleteSequence,
    addEmailAccountToClient,
    addCampaign,
    editCampaign,
    editCampaignSequence,
    editCampaignSchedule,
    editCampaignOptions,
    deleteCampaign,
    duplicateCampaign,
    editProspect,
    commitCampaignPriority,
    uploadBase64Image,
    launchCampaign,
    pauseCampaign,
    restartCampaign,
    addAutomation,
    editAutomation,
    editAutomationField,
    deleteAutomation,
    addNextBatchOfProspects,
    addImmediateBatch,
    accountReset,
    campaignReset,
    deleteNylasAccount,
    addToBlacklist,
    removeFromBlacklist,
    setCustomClaims,
    whitelistDomain,
    campaignRunner,
    editAccount,
    editAccountData,
    setAsClientAccountsDefault,
    deleteProspects,
    testWebhook,
    addInvitation,
    editInvitation,
    editInvitationField,
    deleteInvitation,
    unsubscribe,
    reScore,
    archiveConversation,
    unarchiveConversation,
    toggleArchiveConversation,
    deleteConversation,
    toggleClientrunner,
    clientrunner,
    createCheckoutSession,
    sendEmailVerification: sendEmailVerificationFunction,
    saveOnboarding,
    toggleCampaignOrder,
    toggleWarmupRun,
    manualBackup,
    changeLeadStatus,
    validateGoogleSheet,
    setGoogleSheetMap,
    addAPIKeyToUser,
    markAsRead,
    getUserReportBackend,
    getUserReportFrontend,
  };

  return (
    <ActionsContext.Provider value={{ ...Actions }}>
      <ProgressBar loading={loading} />
      {children}
    </ActionsContext.Provider>
  );
};

ActionsProvider.propTypes = {
  children: PropTypes.node,
};

const useActions = () => {
  const context = useContext(ActionsContext);

  if (!context) throw new Error('useActions must be use inside ActionsProvider');

  return context;
};

export { ActionsContext, ActionsProvider, useActions };
