import { pick } from 'lodash';

export const prepareProspectForConversation = (prospect) => {
  const fieldsForProspect = ['firstName', 'lastName', 'email', 'id', 'sentimentScore', 'status', 'leadStatus'];

  let conversationProspect = prospect;
  if (prospect?.info) {
    conversationProspect = {
      ...prospect,
      ...prospect.info,
    };
  }

  conversationProspect = pick(conversationProspect, fieldsForProspect);
  return conversationProspect;
};
