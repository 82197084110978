import { setDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

import { FirestoreInstance, FirebaseFunctions } from '../contexts/Firebase';

export const getMailbox = httpsCallable(FirebaseFunctions, 'getMailbox');
export const getProspect = httpsCallable(FirebaseFunctions, 'getProspect');
export const getMessages = httpsCallable(FirebaseFunctions, 'getMessages');
export const forwardAll = httpsCallable(FirebaseFunctions, 'forwardAll');

export const toggleArchive = async ({ threadId, uid, prospectId, campaignId, archived }) => {
  const collection = `mailbox/${uid}/conversations/${threadId}`;
  const archive = doc(FirestoreInstance, collection);
  const prospect = doc(FirestoreInstance, `campaigns/${campaignId}/people/${prospectId}`);

  // if prospect exists
  if (prospectId) {
    await updateDoc(prospect, { archived });
  }

  const archiveDoc = await getDoc(archive);
  if (archiveDoc.exists()) {
    await updateDoc(archive, { archived });
  }
};

export const archive = (data) => toggleArchive({ ...data, archived: true });
export const unarchive = (data) => toggleArchive({ ...data, archived: false });

export const markAsRead = async ({ threadId, uid }) => {
  const docPath = `mailbox/${uid}/conversations/${threadId}`;
  const convoRef = doc(FirestoreInstance, docPath);

  await setDoc(convoRef, { unread: false }, { merge: true });
};
