import { useFirestoreDocData, useFirestore } from 'reactfire';
import { doc } from 'firebase/firestore';

export default function useUserRealtime(uid) {
  const firestore = useFirestore();
  const ref = doc(firestore, 'users', uid);
  const { status, data: user } = useFirestoreDocData(ref, {
    idField: 'id',
  });

  return [user, status];
}
