import { PATH_DASHBOARD } from './routes/paths';
import quickmail from './assets/logos/@quickmail.svg';
import lemlist from './assets/logos/@lemlist.png';
import mailshake from './assets/logos/@mailshake.png';

// API
// ----------------------------------------------------------------------

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX ?? 0;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN_ADMIN = PATH_DASHBOARD.general.users; // as '/dashboard/app'
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.dashboard; // as '/dashboard/app'
export const PATH_AFTER_LOGIN_CLIENT = PATH_DASHBOARD.general.dashboard; // as '/dashboard/app'

export const ROLE_ROUTES = {
  OWNER: PATH_AFTER_LOGIN,
  ADMIN: PATH_AFTER_LOGIN,
  USER: PATH_AFTER_LOGIN,
  CLIENT: PATH_AFTER_LOGIN_CLIENT,
};

export const ROLE_ROUTES_BY_ALIAS = {
  Superadmin: PATH_AFTER_LOGIN,
  Admin: PATH_AFTER_LOGIN,
  User: PATH_AFTER_LOGIN,
  Client: PATH_AFTER_LOGIN_CLIENT,
};

export const ROLE_ALIAS = {
  ADMIN: 'Admin',
  SUPERADMIN: 'Superadmin',
  USER: 'User',
  CLIENT: 'Client',
};

export const getAlias = (roles) => {
  if (!roles) return 'no roles';
  if (roles.includes('CLIENT') && roles.includes('USER')) return 'solo';
  const actualroles = [...roles].pop();
  return ROLE_ALIAS[actualroles];
};
export const getRoleRoute = (roles) => {
  let role;
  if (roles && roles.length > 1) {
    if (roles.includes('OWNER') && roles.includes('ADMIN')) {
      role = 'ADMIN';
    } else role = [...roles.pop()];
  }
  if (roles && roles.length === 1) role = roles;
  return ROLE_ROUTES[role];
};

// LAYOUT
// ----------------------------------------------------------------------
const NU_HEADER_HEIGHT = 64;

export const HEADER = {
  MOBILE_HEIGHT: 48,
  MAILBOX_HEADER_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: NU_HEADER_HEIGHT,
  DASHBOARD_DESKTOP_HEIGHT: NU_HEADER_HEIGHT,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: NU_HEADER_HEIGHT - 32,
  NU_HEADER_HEIGHT,
};

export const NAVBAR = {
  BASE_WIDTH: 220,
  DASHBOARD_WIDTH: 260,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: true,
};

export const MAILITY = {
  EMAIL_SENDING_PROVIDERS: {
    Quickmail: {
      name: 'Quickmail',
      image: quickmail,
    },
    Mailshake: {
      name: 'Mailshake',
      image: mailshake,
    },
    Lemlist: {
      name: 'Lemlist',
      image: lemlist,
    },
  },
  PAYMENT_MODELS: ['Retainer', 'Setup fee'],
  PERFORMANCE_MODELS: ['Pay-per-appointment', 'Positive replies', 'None'],

  CLIENT_MODELS: {
    Performance: {
      name: 'Performance',
      fields: ['model', 'modelType'],
      modelTypes: ['Pay-per-appointment', 'Positive replies', 'None'],
      defaultAmount: 300,
      defaultType: 'Pay-per-appointment',
      formText: 'PPA / Replies / None',
    },
    Retainer: {
      name: 'Retainer',
      fields: ['model', 'modelType'],
      modelTypes: ['Retainer', 'Setup fee'],
      defaultAmount: 1500,
      defaultType: 'Retainer',
      formText: 'Denomination',
    },
    None: {
      name: 'None',
      formText: 'None',
    },
  },
};

export const UserFields = ['accessToken', 'displayName', 'emailVerified', 'isAnonymous', 'metadata', 'photoURL', 'uid'];

export const EDITOR_VALUES = [
  ['bold', 'italic', 'underline', 'link', 'image'],
  ['unorderedList', 'h1', 'h2', 'h3'],
];

export const WEEKDAYS = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  0: 'Sunday',
};

export const PROSPECTS = {
  FIELDS: {
    companyName: {
      key: 'companyName',
      fullKey: 'Company Name',
      example: 'Apple',
    },
    firstName: {
      key: 'firstName',
      fullKey: 'First Name',
      alts: ['name', 'personFirstName'],
      example: 'John',
    },
    lastName: {
      key: 'lastName',
      alts: ['last', 'person_last_name'],
      fullKey: 'Last Name',
      example: 'Doe',
    },
    role: {
      key: 'role',
      fullKey: 'Role',
      alts: ['JobTitle', 'Job', 'Position', 'PersonJobTitle'],
      example: 'CEO',
    },
    email: {
      key: 'email',
      fullKey: 'Email',
      alts: ['EmailAddress', 'person_business_email'],
      example: 'john@apple.com',
    },
    phone: {
      key: 'phone',
      fullKey: 'Phone',
      alts: ['PhoneNumber', 'Personal_Number', 'person_phone'],
      example: '0034 676 50 80 02',
    },
    city: {
      key: 'city',
      fullKey: 'City',
      example: 'Houston',
    },
    state: {
      key: 'state',
      fullKey: 'State',
      example: 'Texas',
    },
    country: {
      key: 'country',
      fullKey: 'Country',
      example: 'USA',
    },
    website: {
      key: 'website',
      fullKey: 'Website',
      alts: ['CompanyWebsite'],
      example: 'https://apple.com',
    },
    linkedInProfile: {
      key: 'linkedInProfile',
      fullKey: 'LinkedIn Profile',
      alts: [
        'LinkedIn Url',
        'LinkedinProfileURL',
        'LinkedInProfile',
        'LinkedIn',
        'Url_Linkedin',
        'profileUrl',
        'PersonLinkedinUrl',
      ],
      example: 'https://linkedin.com/u/johndoe',
    },
    firstLine: {
      key: 'firstLine',
      fullKey: 'First Line',
      alts: ['PersonalizedFirstLine', 'Icebreaker', 'Personalization'],
      example: 'I love what you did last summer',
    },
    employeeCount: {
      key: 'employeeCount',
      fullKey: 'Employee Count',
      alts: ['EmployeeCount', 'NumberOfEmployees', '#Employees'],
      example: '100',
    },
    revenue: {
      key: 'revenue',
      fullKey: 'Revenue',
      alts: ['RevenueAmount', 'RevenueAmountUSD'],
      example: '100000',
    },
    industry: {
      key: 'industry',
      fullKey: 'Industry',
      alts: ['IndustryName', 'IndustryType'],
      example: 'Technology',
    },
    campaignName: {
      key: 'campaignName',
      fullKey: 'Campaign Name',
      alts: ['CampaignName', 'Campaign'],
      example: 'Summer Vacation',
    },
    function: {
      key: 'function',
      fullKey: 'Function',
      alts: ['FunctionName', 'FunctionType'],
      example: 'Sales',
    },
    seniority: {
      key: 'seniority',
      fullKey: 'Seniority',
      alts: ['SeniorityLevel', 'SeniorityLevelName'],
      example: 'Senior',
    },
  },
  STATUS: {
    bucket: {
      key: 'bucket',
      fullKey: 'Bucket',
      color: 'default',
      description: 'Prospect is in the bucket. Prospects are added to campaign based on your drip settings in Options.',
    },
    ready: {
      key: 'ready',
      fullKey: 'Ready',
      color: 'secondary',
      description: `Prospect's next email is scheduled to be sent.`,
    },
    contacted: {
      key: 'contacted',
      fullKey: 'Contacted',
      color: 'warning',
      description: 'Prospect has been contacted.',
    },
    opened: {
      key: 'opened',
      fullKey: 'Opened',
      color: 'info',
      description: 'Prospect has opened your email.',
    },
    clicked: {
      key: 'clicked',
      fullKey: 'Clicked',
      color: 'info',
      description: 'Prospect has clicked a link in your email.',
    },
    replied: {
      key: 'replied',
      fullKey: 'Replied',
      color: 'primary',
      description: 'Prospect has replied to your emails.',
    },
    unsubscribed: {
      key: 'unsubscribed',
      fullKey: 'Unsubscribed',
      color: 'error',
      description: 'Prospect has unsubscribed from your emails.',
    },
    bounced: {
      key: 'bounced',
      fullKey: 'Bounced',
      color: 'error',
      description: `Prospect's email wasn't found.`,
    },
    negative: {
      key: 'negative',
      fullKey: 'Negative',
      color: 'error',
    },
    completed: {
      key: 'completed',
      fullKey: 'Completed',
      color: 'success',
    },
    invalid: {
      key: 'invalid',
      fullKey: 'Invalid',
      color: 'error',
    },
  },
  LEADSTATUS: {
    NoStatus: {
      key: 'none',
      text: 'No status',
    },
    MeetingBooked: {
      key: 'MeetingBooked',
      text: 'Meeting booked',
    },
    FollowUpNeeded: {
      key: 'FollowUpNeeded',
      text: 'FollowUp needed',
    },
    NotInterested: {
      key: 'NotInterested',
      text: 'Not interested',
    },
    Interested: {
      key: 'Interested',
      text: 'Interested',
    },
    OutOfOffice: {
      key: 'OutOfOffice',
      text: 'Out of office',
    },
    Closed: {
      key: 'Closed',
      text: 'Closed',
    },
  },
};

export const SENDER = {
  FIELDS: {
    firstName: {
      key: 'senderFirstName',
      fullKey: 'First Name',
      example: 'John',
    },
    lastName: {
      key: 'senderLastName',
      fullKey: 'Last Name',
      example: 'Doe',
    },
    company: {
      key: 'senderCompany',
      fullKey: 'Company',
      example: 'Apple',
    },
    companyWebsite: {
      key: 'senderCompanyWebsite',
      fullKey: 'Company Website',
      example: 'https://apple.com',
    },
    email: {
      key: 'senderEmail',
      fullKey: 'Email',
      example: 'john@apple.com',
    },
  },
};
export const TEAM_USERS_LEGACY = {
  ROLE: {
    SUPERADMIN: {
      key: 'SUPERADMIN',
      title: 'SuperAdmin',
      color: 'warning',
    },
    ADMIN: {
      key: 'ADMIN',
      title: 'Admin',
      color: 'secondary',
    },
    USER: {
      key: 'USER',
      title: 'User',
      color: 'primary',
    },
    CLIENT: {
      key: 'CLIENT',
      title: 'Client',
      color: 'default',
    },
    'Team Leader': {
      key: 'USER',
      title: 'User',
      color: 'primary',
    },
    'Team Member': {
      key: 'ADMIN',
      title: 'Admin',
      color: 'secondary',
    },
  },
};

export const TEAM_USERS = {
  teamLeader: {
    key: 'teamLeader',
    title: 'Admin',
    color: 'primary',
  },
  teamManager: {
    key: 'teamManager',
    title: 'Team Manager',
    color: 'secondary',
  },
  inboxManager: {
    key: 'inboxManager',
    title: 'Inbox Manager',
    color: 'default',
  },
  copywriter: {
    key: 'copywriter',
    title: 'Copywriter',
    color: 'warning',
  },
  readOnly: {
    key: 'readOnly',
    title: 'Read Only',
    color: 'error',
  },
};

export const CAMPAIGNS = {
  STATUS: {
    draft: {
      key: 'draft',
      title: 'Draft',
      color: 'warning',
      icon: 'mdi-pencil',
    },
    ready: {
      key: 'ready',
      title: 'Ready',
      color: 'secondary',
      icon: 'mdi-check',
    },
    running: {
      key: 'running',
      title: 'Running',
      color: 'primary',
      icon: 'mdi-play',
    },
    paused: {
      key: 'paused',
      title: 'Paused',
      color: 'info',
      icon: 'mdi-pause',
    },
    completed: {
      key: 'completed',
      title: 'Completed',
      color: 'default',
      icon: 'mdi-check-all',
    },
  },
  OPTIONS: {
    SEND_INTERVAL: 7,
    PRIORITY: 60,
    NONPRIORITY: 40,
    MAX_BUCKET_DRIP: 1000,
    MAX_HARD_LIMIT: 50,
  },
  CONDITIONS: {
    hasPeople: {
      error: 'Please add people to your campaign before launch',
    },
    hasSequence: {
      error: 'Please add a sequence to your campaign before launch',
    },
    hasAccounts: {
      error: 'Please add accounts to your campaign before launch',
    },
  },
};

export const JOBS = {
  STATUS: {
    scheduled: {
      key: 'scheduled',
      title: 'Scheduled',
      color: 'secondary',
    },
    pending: {
      key: 'pending',
      title: 'Pending',
      color: 'warning',
    },
    completed: {
      key: 'completed',
      title: 'Completed',
      color: 'primary',
    },
  },
};

export const ACTIVITIES = {
  ACTIONS: {
    imported: {
      // This one's on the prospect
      key: 'importedPeople',
      title: 'Imported people',
      icon: 'ant-design:user-add-outlined',
      color: 'secondary',
    },
    addedProspects: {
      // This one's on journey creation
      key: 'addedPeople',
      title: 'Added people',
      icon: 'ant-design:user-add-outlined',
      color: 'secondary',
    },
    leadStatus: {
      // email sent
      key: 'leadStatus',
      title: 'leadStatus',
      icon: 'fluent:person-voice-20-filled',
      color: 'info',
    },
    contacted: {
      // email sent
      key: 'contacted',
      title: 'Contacted',
      icon: 'fluent:person-voice-20-filled',
      color: 'primary',
    },
    emailSent: {
      // email sent
      key: 'emailSent',
      title: 'Email sent',
      icon: 'wpf:sent',
      color: 'secondary',
    },
    threadReplied: {
      // threadReplied
      key: 'threadReplied',
      title: 'Replied',
      icon: 'bi:reply-all-fill',
      color: 'primary',
    },
    messageOpened: {
      // activity
      key: 'messageOpened',
      title: 'Message opened',
      icon: 'line-md:email-opened-twotone',
      color: 'info',
    },
    emailLinkClicked: {
      // activity
      key: 'emailLinkClicked',
      title: 'Link clicked',
      icon: 'bx:link',
      color: 'success',
    },
    bounce: {
      // prospect, activity, journey
      key: 'bounce',
      title: 'Bounce',
      icon: 'fluent:person-voice-20-filled',
      color: 'error',
    },
    unsubscribe: {
      // prospect, activity, journey
      key: 'unsubscribe',
      title: 'Unsubscribe',
      icon: 'ic:baseline-unsubscribe',
      color: 'error',
    },
    complete: {
      key: 'journeyCompleted',
      title: 'Complete',
      icon: 'carbon:circle-filled',
      color: 'success',
    },
  },
  GRAPH: {
    ACTIONS: {
      imported: {
        // This one's on the prospect
        key: 'importedPeople',
        title: 'Imported people',
        icon: 'ant-design:user-add-outlined',
        color: 'secondary',
      },
      addedProspects: {
        // This one's on journey creation
        key: 'addedPeople',
        title: 'Added people',
        icon: 'ant-design:user-add-outlined',
        color: 'secondary',
      },
      leadStatus: {
        // email sent
        key: 'leadStatus',
        title: 'leadStatus',
        icon: 'fluent:person-voice-20-filled',
        color: 'info',
      },
      contacted: {
        // email sent
        key: 'contacted',
        title: 'Contacted',
        icon: 'fluent:person-voice-20-filled',
        color: 'primary',
      },
      emailSent: {
        // email sent
        key: 'emailsSent',
        title: 'Email sent',
        icon: 'wpf:sent',
        color: 'secondary',
      },
      threadReplied: {
        // threadReplied
        key: 'replies',
        title: 'Replied',
        icon: 'bi:reply-all-fill',
        color: 'primary',
      },
      messageOpened: {
        // activity
        key: 'opens',
        title: 'Message opened',
        icon: 'line-md:email-opened-twotone',
        color: 'info',
      },
      emailLinkClicked: {
        // activity
        key: 'clicks',
        title: 'Links clicked',
        icon: 'bx:link',
        color: 'success',
      },
      bounce: {
        // prospect, activity, journey
        key: 'bounces',
        title: 'Bounce',
        icon: 'fluent:person-voice-20-filled',
        color: 'error',
      },
      unsubscribe: {
        // prospect, activity, journey
        key: 'unsubscribes',
        title: 'Unsubscribe',
        icon: 'ic:baseline-unsubscribe',
        color: 'error',
      },
      complete: {
        key: 'complete',
        title: 'Complete',
        icon: 'carbon:circle-filled',
        color: 'success',
      },
      uniqueSends: {
        key: 'uniqueSends',
        title: 'Unique sends',
        icon: 'fluent:person-voice-20-filled',
        color: 'primary',
      },
      uniqueOpens: {
        key: 'uniqueOpens',
        title: 'Unique opens',
        icon: 'line-md:email-opened-twotone',
        color: 'info',
      },
      uniqueClicks: {
        key: 'uniqueClicks',
        title: 'Unique clicks',
        icon: 'bx:link',
        color: 'success',
      },
      uniqueReplies: {
        key: 'uniqueReplies',
        title: 'Unique replies',
        icon: 'bi:reply-all-fill',
        color: 'primary',
      },
    },
  },
};

export const AUTOMATIONS = {
  STATUS: {
    draft: {
      key: 'draft',
      title: 'Draft',
      color: 'warning',
    },
    active: {
      key: 'active',
      title: 'Active',
      color: 'secondary',
    },
    running: {
      key: 'running',
      title: 'Running',
      color: 'primary',
    },
  },
};

export const ACTIVITY = [
  // {
  //   key: 'added',
  //   title: 'Added',
  //   icon: 'ant-design:user-add-outlined',
  //   description: 'When a prospect is added to a campaign',
  // },
  {
    key: 'emailSent',
    title: 'Email sent',
    icon: 'wpf:sent',
    description: 'When an email is sent',
    leadScore: `Doesn't add or subtract from Lead Score`,
  },
  {
    key: 'threadReplied',
    title: 'Replied',
    icon: 'bi:reply-all-fill',
    description: 'When a message / thread has been replied to',
    leadScore: `Goes through Sentiment Analysis and sets the Lead Score accordingly`,
  },
  {
    key: 'messageOpened',
    title: 'Message opened',
    icon: 'fluent:person-voice-20-filled',
    description: 'When a message is opened',
    leadScore: `Adds 0.1 to Lead Score every time the message is opened`,
  },
  {
    key: 'emailLinkClicked',
    title: 'Links clicked',
    icon: 'fluent:person-voice-20-filled',
    description: 'When a link is clicked',
    leadScore: `Adds 0.25 to Lead Score every time the link is clicked`,
  },
  {
    key: 'leadStatus',
    title: 'Lead changes status',
    icon: 'icon-park-outline:user-business',
    description: 'When a lead changes status',
  },
  {
    key: 'unsubscribe',
    title: 'Unsubscribe',
    icon: 'ic:baseline-unsubscribe',
    description: 'When a user unsubscribes',
    leadScore: `Sets the Lead Score to -1 (negative)`,
  },
  {
    key: 'bounce',
    title: 'Bounce',
    icon: 'iconoir:bounce-right',
    description: 'When an email bounces',
    leadScore: `Sets the Lead Score to 0 (neutral)`,
  },
];

export const ACCOUNTS = {
  PROVIDERS: {
    GMAIL: {
      icon: 'eva:google-fill',
    },
  },
  STATUS: {
    RUNNING: {
      color: 'primary',
      tooltip: 'Account is connected and active',
    },
    INVALID: {
      color: 'error',
      tooltip: 'Please reconnect your account',
    },
  },
  OPTIONS: {
    SEND_INTERVAL: 7,
    DEFAULT_EMAILS_PER_ACCOUNT_PER_DAY: 30,
    MAX_EMAILS_PER_ACCOUNT_PER_DAY: 80,
    MAX_WARMUP_RAMP: 5,

    INIT_RAMP: 0,
    INIT_LIMIT: 5,
    INIT_HARD_LIMIT: 30,

    DEFAULT_HARD_LIMIT: 100,

    MAX_RAMP: 10,
    MAX_LIMIT: 50,
    MAX_HARD_LIMIT: 1000,

    INIT_WARMUP_RAMP: 0,
    INIT_WARMUP_LIMIT: 12,
    INIT_WARMUP_HARD_LIMIT: 30,
  },
};

export const SIGNATURES = {
  FIELDS: {
    emailLink: {
      key: 'emailLink',
      title: 'Email link',
    },
    unsubscribe: {
      key: 'unsubscribe',
      title: 'Unsubscribe',
    },
  },
};

export const SENTIMENT = {
  POSITIVE: {
    key: 'positive',
    color: 'success',
    iconSize: 20,
    icon: 'twemoji:thumbs-up',
    from: 0.5,
    to: 1,
  },
  NEGATIVE: {
    key: 'negative',
    color: 'error',
    iconSize: 20,
    icon: 'twemoji:thumbs-down',
    from: -1,
    to: -0.5,
  },
  NEUTRAL: {
    key: 'neutral',
    color: 'warning',
    iconSize: 24,
    icon: 'flat-color-icons:neutral-decision',
    from: -0.5,
    to: 0.5,
    // image: '/logo/djacob.png',
  },
};

export const CSVFields = [
  {
    key: 'date',
    label: 'Date',
  },
  {
    key: 'uid',
    label: 'User ID',
  },
  {
    key: 'userEmail',
    label: 'User email',
  },
  {
    key: 'clientId',
    label: 'Client ID',
  },
  {
    key: 'clientName',
    label: 'Client name',
  },
  {
    key: 'clientEmail',
    label: 'Client email',
  },
  {
    key: 'campaignId',
    label: 'Campaign ID',
  },
  {
    key: 'campaignName',
    label: 'Campaign name',
  },
  {
    key: 'peopleImported',
    label: 'People imported',
  },
  {
    key: 'peopleAddedToCampaign',
    label: 'People added to campaign',
  },
  {
    key: 'emailsSent',
    label: 'Emails sent',
  },
  {
    key: 'emailsOpened',
    label: 'Emails opened',
  },
  {
    key: 'emailsClicked',
    label: 'Emails clicked',
  },
  {
    key: 'replies',
    label: 'Replies',
  },
  {
    key: 'unsubscribes',
    label: 'Unsubscribes',
  },
  {
    key: 'bounces',
    label: 'Bounces',
  },
  {
    key: 'errors',
    label: 'Errors',
  },
  {
    key: 'completedJourneys',
    label: 'Completed journeys',
  },
];

export const CLIENT_INDUSTRIES = [
  'Advertising',
  'Affiliate marketing',
  'Analytics and Metrics',
  'Blogging',
  'Conversion',
  'Courses',
  'Digital Products',
  'Email marketing',
  'Freelance Service',
  'Goal Setting',
  'Hiring',
  'Mission, Vision & Values',
  'Money',
  'Physical Products',
  'Podcasting',
  'Productivity',
  'SaaS',
  'Sales',
  'Social Media',
  'Sponsorship',
  'Tools',
  'Video',
  'Web Design',
  'Web Development',
  'Website optimization',
  'Other industries',
];

export const bouncedStrings = [
  'Message bounced',
  '451 4.4.4',
  '550 Requested action not taken: mailbox unavailable',
  '550 5.1.10',
  '550 5.1.1',
  '554 5.4.14',
  '550 5.4.1',
  '550 #5.7.1',
  '554 5.7.1',
  '550 5.7.1',
  'Error code 5.4.12',
  'sent could not be delivered',
  'A delivery loop was detected',
  'message could not be delivered',
  `DNS Error: DNS type 'mx' lookup of`,
  'Address not found',
  `Your message wasn't delivered`,
  'The following address(es) failed',
  'There was a problem delivering your message',
  `The recipient's mailbox is full`,
];

export const SOLO_LIMIT = 10;
export const ACCOUNT_HARD_LIMIT = 500;

export const SOLO_ID = process.env.REACT_APP_STRIPE_PRODUCT_SOLO_ID;
export const AGENCY_LITE_ID = process.env.REACT_APP_STRIPE_PRODUCT_AGENCY_LITE_ID;
export const AGENCY_ID = process.env.REACT_APP_STRIPE_PRODUCT_AGENCY_ID;
export const ENTERPRISE_ID = process.env.REACT_APP_STRIPE_PRODUCT_ENTERPRISE_ID;
export const DOMAIN_PACKAGES_ID = process.env.REACT_APP_STRIPE_PRODUCT_DOMAIN_PACKAGES_ID;

export const SUBSCRIPTIONS = {
  solo: {
    id: SOLO_ID,
    name: 'Solo',
    role: 'solo',
  },
  agencyLite: {
    id: AGENCY_LITE_ID,
    name: 'Agency Lite',
    role: 'agency',
  },
  agency: {
    id: AGENCY_ID,
    name: 'Agency',
    role: 'agency',
  },
  enterprise: {
    id: ENTERPRISE_ID,
    name: 'Enterprise',
    role: 'agency',
  },
  domainPackages: {
    id: DOMAIN_PACKAGES_ID,
    name: 'Domain packages',
    role: null,
  },
};

export const MAILBOX_FIRST_LOAD = 30;
