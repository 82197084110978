import { httpsCallable } from 'firebase/functions';
import { FirebaseFunctions } from '../contexts/Firebase';

// RUN ROBOT MAILER LOCALLY:

export const clientrunner = httpsCallable(FirebaseFunctions, 'clientrunnerCallable');
export const batchRunner = httpsCallable(FirebaseFunctions, 'batchRunner');
export const accountReset = httpsCallable(FirebaseFunctions, 'accountReset');
export const campaignReset = httpsCallable(FirebaseFunctions, 'campaignReset');
export const toggleClientrunner = httpsCallable(FirebaseFunctions, 'toggleClientrunner');
